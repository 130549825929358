<template>
    <div class="container">
        <div class="container marketing">

            <div class="p-5 mb-4 bg-light rounded-3 banner-background">
                <div class="container-fluid py-5">
                    <h1 class="display-5 fw-bold">Extincteur de France</h1>
                    <p class="col-md-8 fs-4">La Protection Incendie Simplifiée pour les Particuliers.</p>
                    <RouterLink to="/products">
                        <a >Commencez dès Maintenant ></a>
                    </RouterLink>
                </div>
            </div>

            <hr class="featurette-divider my-5">
            <div class="container-fluid py-5 text-center">
                <h2>
                    95% des particuliers ne sont pas protégés contre l'incendie, et vous ?
                </h2>
                <p>
                    340 000 incendies domestiques sont déclarés chaque année en France. <br /> <b>Protégez votre famille et
                        votre domicile avec un extincteur.</b>
                </p>

                <h3>
                    Notre configurateur vous permet de trouver l'extincteur adapté à votre domicile en quelques clics.
                </h3>

                <RouterLink to="/products">
                        <a >Commencez dès Maintenant ></a>
                    </RouterLink>
            </div>
            <!-- divider -->

            <hr class="featurette-divider my-5">

            <!-- Solutions / Services -->
            <div class="row ">
                <div class="col-lg-4 text-center">
                    <h2>Maitenance et Garanties Comprise</h2>
                    <p>
                        Nous savons que la maintenance des extincteurs est une contrainte pour vous. C'est pourquoi nous
                        vous proposons une solution tout compris : <br /> <b>achat, installation facile, maintenance et
                            garantie.</b>
                    </p>
                </div><!-- /.col-lg-4 -->
                <div class="col-lg-4 text-center">
                    <h2>
                        Produit de Qualité et Normé en France
                    </h2>
                    <p>
                        Nos produits sont fabriqués en France et sont conformes aux normes en vigueur. <br /> <b>Vous êtes
                            assurés d'avoir un produit de qualité et de confiance.</b>
                    </p>
                </div><!-- /.col-lg-4 -->
                <div class="col-lg-4 text-center">
                    <h2>
                        Notifications et Rappels
                    </h2>
                    <p>
                        Nous vous envoyons des notifications et des rappels pour vous aider à maintenir vos extincteurs en
                        bon état. <br /> <b>Vous êtes assurés d'avoir un produit de qualité et de confiance.</b>
                    </p>
                </div><!-- /.col-lg-4 -->
            </div><!-- /.row -->

        </div>
    </div>
</template>

<script>
export default {
    name: 'LandingComponent',
    components: {},
    props: [],
    data() {
        return {}
    },
    computed: {},
    mounted() { },
}
</script>

<style scoped>
</style>