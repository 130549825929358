<template>
  <div>
    <LandingComponent />
    <FAQComponent />
  </div>
</template>

<script>
import FAQComponent from '@/components/FAQComponent.vue';
import LandingComponent from '@/components/Home/LandingComponent.vue'
export default {
  name: 'HomeView',
  components: {
    LandingComponent,
    FAQComponent
  },
  mounted() {
    document.title = 'Extincteur de France - Vente d\'extincteurs et de matériel de sécurité incendie';
    document.querySelector('meta[name="description"]').setAttribute("content", 'Extincteur de France propose un abonnement annuel unique pour la sécurité incendie des particuliers. Utilisez notre configurateur en ligne pour une protection personnalisée. Livraison et maintenance incluses. Commencez dès maintenant !');
  }
}
</script>
