<template>
    <div class="container py-lg-3 my-lg-3">
        <div class="row">
            <div class="col-12 text-center pt-5 pb-5">
                <h2>Support en France</h2>
            </div>
            <div class="col-12 text-center">
                <img class="img-fluid" src="/img/bergerac.png" alt="bergerac" height="400">
                <h3>Bergerac, France</h3>
                <p>
                    contact@extincteurdefrance.fr
                </p>
                <p>
                    +33 9 48 67 45 78
                </p>
            </div>
        </div>
    </div>

    <footer>
        <div class="container-fluid mb-3">
            <div class="row pt-5 mx-3 pb-5 gradient-background">
                <div class="col-lg-4 text-center">
                    <img src="/img/logo.png" alt="logo" height="50" />
                    <template v-if="false">
                        <b>Mon compte</b>
                        <ul class="list-unstyled">
                            <li>Ma commande</li>
                        </ul>
                    </template>
                </div>
                <div class="col-lg-4 text-center">
                    <b>Informations</b>
                    <ul class="list-unstyled">
                        <RouterLink to="/conditions-generales-de-vente">
                            <li>CGV</li>
                        </RouterLink>
                        <RouterLink to="/vie-privee">
                            <li>Politique de confidentialité</li>
                        </RouterLink>
                        <li><a href="/sitemap.xml">Plan du site</a></li>
                    </ul>
                </div>

                <div class="col-lg-4 text-center">
                    <b>Service client</b>
                    <ul class="list-unstyled">
                        <RouterLink to="/contact">
                            <li>Contactez-nous</li>
                        </RouterLink>
                        <RouterLink to="/retours">
                            <li>Retours</li>
                        </RouterLink>
                        <RouterLink to="/garanties">
                            <li>Garanties</li>
                        </RouterLink>
                    </ul>
                </div>
                <hr class="my-3" />
                <div class="col-12 text-center">
                    <img src="/img/logo.png" alt="logo" height="20">
                </div>
                <div class="col-12 text-center">
                    <p>
                        © 2023-2024 Extincteur De France. Tous droits réservés.
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterComponent',
    components: {},
    props: [],
    data() {
        return {}
    },
    computed: {},
    mounted() { },
    methods: {}
}
</script>