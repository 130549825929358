
<template>
    <!-- navbar -->
    <nav class="navbar navbar-expand-lg d-none d-md-block">
        <div class="container-fluid">
            <ul class="navbar-nav mx-auto">
                <li class="nav-item">
                    <RouterLink class="nav-link" to="/">
                        <img class="logo" src="/img/logo.png" alt="logo" height="20">
                    </RouterLink>
                </li>
                <li class="nav-item">
                    <router-link to="/" custom v-slot="{ href, navigate, isActive, isExactActive }">
                        <a class="nav-link" :href="href" :class="{
                            active: isActive,
                            'exact-active': isExactActive,
                        }" @click="navigate">
                            Accueil
                        </a>
                    </router-link>
                </li>
                <li class="nav-item mx-5">
                    <router-link to="/products" custom v-slot="{ href, navigate, isActive, isExactActive }">
                        <a class="nav-link" :href="href" :class="{
                            active: isActive,
                            'exact-active': isExactActive,
                        }" @click="navigate">
                            Boutique
                        </a>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/contact" custom v-slot="{ href, navigate, isActive, isExactActive }">
                        <a class="nav-link" :href="href" :class="{
                            active: isActive,
                            'exact-active': isExactActive,
                        }" @click="navigate">Aide</a>
                    </router-link>
                </li>
            </ul>
        </div>
    </nav>

    <!-- responsive navbar -->
    <nav class="navbar navbar-expand-lg d-block d-md-none">
        <div class="container-fluid">
            <RouterLink class="navbar-brand" to="/">
                <img class="logo" src="/img/logo.png" alt="logo" height="20">
            </RouterLink>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive"
                aria-controls="navbarResponsive" aria-expanded="true" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse collapse" id="navbarResponsive">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item">
                        <router-link to="/" custom v-slot="{ href, navigate, isActive, isExactActive }">
                            <a class="nav-link" :href="href" :class="{
                                active: isActive,
                                'exact-active': isExactActive,
                            }" @click="navigate">
                                Accueil
                            </a>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/products" custom v-slot="{ href, navigate, isActive, isExactActive }">
                            <a class="nav-link" :href="href" :class="{
                                active: isActive,
                                'exact-active': isExactActive,
                            }" @click="navigate">
                                Boutique
                            </a>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/contact" custom v-slot="{ href, navigate, isActive, isExactActive }">
                            <a class="nav-link" :href="href" :class="{
                                active: isActive,
                                'exact-active': isExactActive,
                            }" @click="navigate">Aide</a>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <!-- Grey banner with 3 reasurance -->
    <div class="col-12 px-3">

        <div class="alert bg-light col-12" role="alert">
            <div class="container">
                <div class="row">
                    <div class="col-sm text-center">
                        <!-- Garanties -->
                        <i class="bi-shield-check mx-3"></i>
                        <span>Garanties 100% Satisfait ou Remboursé</span>
                    </div>
                    <div class="col-sm text-center">
                        <span class="mx-3">🇫🇷</span>
                        <span>Produit en France</span>
                    </div>
                    <div class="col-sm text-center">
                        <i class="bi-people mx-3"></i>
                        <span>Des experts pour
                            vous répondre</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <router-view v-slot="{ Component, route }">
        <transition name="fade" mode="out-in">
            <div :key="route.name">
                <component :is="Component"></component>
            </div>
        </transition>
    </router-view>
    <footer-component></footer-component>
</template>

<script>
import FooterComponent from './components/FooterComponent.vue';

export default {
    name: 'App',
    components: { FooterComponent },
    props: [],
    data() {
        return {}
    },
    computed: {},
    mounted() { },
}
</script>

<style lang="scss">
.gradient-background {
    /* gradient linear  */
    background: linear-gradient(rgba(255, 255, 255, 0.07), rgba(0, 0, 0, 0.09));
    border-radius: 20px;
}

body {
    font-family: Roboto, sans-serif;
    font-weight: 300;
}

.product-card:hover {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    transform: scale(1.01);
    transition: all 0.1s ease-in-out;
    cursor: pointer;
}

.exact-active {
    color: #0d6efd !important;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
    opacity: 1;
}
</style>
