import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    name: '/products',
    path: '/products',
    component: () => import('../views/ProductsView.vue'),
  },
  {
    path: '/product/:slug',
    name: 'product',
    component: () => import('../views/ProductView.vue'),
    meta: {
      seo_title: 'Produit - Extincteur de France',
    }
  },
  {
    path: '/conditions-generales-de-vente',
    name: 'conditions',
    component: () => import('../views/CgvView.vue'),
    meta: {
      seo_title: 'Conditions générales de vente - Extincteur de France',
    }
  },
  {
    path: '/vie-privee',
    name: 'vie-privee',
    component: () => import('../views/PrivacyView.vue'),
    meta: {
      seo_title: 'Vie privée - Extincteur de France',
    }
  },
  {
    path: '/retours',
    name: 'retours',
    component: () => import('../views/ReturnView.vue'),
    meta: {
      seo_title: 'Retour - Extincteur de France',
    }
  },
  // Garanties
  {
    path: '/garanties',
    name: 'garanties',
    component: () => import('../views/GuaranteeView.vue'),
  },
  // contact
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/ContactView.vue'),
    meta: {
      seo_title: 'Contact - Extincteur de France',
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  window.scrollTo(0, 0)
  document.title = to.meta.seo_title || 'Extincteur de France'
})

export default router
