<template>
    <!-- On répond à vos questions -->
<div class="container py-lg-3 my-lg-3">
    <div class="row">
        <div class="col-12 text-center pt-5 pb-5">
            <h2>On répond à vos questions</h2>
        </div>
        <div class="col-12">
            <div class="accordion accordion-flush" id="accordionFlushExample">

                <!-- Question 1 -->
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne" aria-expanded="false"
                            aria-controls="flush-collapseOne">
                            Qu'est-ce qu'un extincteur et comment fonctionne-t-il?
                        </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                            Un extincteur est un dispositif de lutte contre l'incendie. Il fonctionne en éjectant un agent
                            extincteur (tel que de la poudre ou de l'eau) sur la source du feu pour le priver d'oxygène
                            ou le refroidir.
                        </div>
                    </div>
                </div>

                <!-- Question 2 -->
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo" aria-expanded="false"
                            aria-controls="flush-collapseTwo">
                            Comment choisir le bon extincteur pour mon domicile?
                        </button>
                    </h2>
                    <div id="flush-collapseTwo" class="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                            Pour choisir le bon extincteur, considérez la taille de votre domicile, les types de feux
                            possibles et la présence d'équipements électroniques. Consultez notre guide de sélection
                            pour plus d'informations.
                        </div>
                    </div>
                </div>

                <!-- Question 3 -->
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree" aria-expanded="false"
                            aria-controls="flush-collapseThree">
                            Comment installer correctement un extincteur?
                        </button>
                    </h2>
                    <div id="flush-collapseThree" class="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                            L'installation correcte d'un extincteur implique de le placer dans des endroits stratégiques,
                            accessibles et conformes aux consignes de sécurité. Consultez notre guide d'installation pour
                            des instructions détaillées.
                        </div>
                    </div>
                </div>

                <!-- Question 4 -->
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFour" aria-expanded="false"
                            aria-controls="flush-collapseFour">
                            À quelle fréquence faut-il vérifier l'état de l'extincteur?
                        </button>
                    </h2>
                    <div id="flush-collapseFour" class="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                            Il est recommandé de vérifier l'état de votre extincteur au moins une fois par mois et de
                            le faire inspecter annuellement par un professionnel pour assurer son bon fonctionnement.
                        </div>
                    </div>
                </div>

                <!-- Question 5 -->
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFive" aria-expanded="false"
                            aria-controls="flush-collapseFive">
                            Où devrais-je installer mon détecteur de fumée?
                        </button>
                    </h2>
                    <div id="flush-collapseFive" class="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                            Installez votre détecteur de fumée dans des endroits stratégiques, tels que les couloirs et
                            les chambres à coucher, et évitez les zones où la fumée normale peut déclencher des fausses
                            alarmes.
                        </div>
                    </div>
                </div>

                <!-- Question 6 -->
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseSix" aria-expanded="false"
                            aria-controls="flush-collapseSix">
                            Quelle est la garantie sur les extincteurs?
                        </button>
                    </h2>
                    <div id="flush-collapseSix" class="accordion-collapse collapse"
                        data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                            Nos extincteurs sont couverts par une garantie standard d'un an. Consultez nos conditions de
                            garantie pour plus de détails.
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

</template>

<script>
export default {
    name: 'FAQComponent',
    components: {

    }
}
</script>